import {closeModal, openModal, initializeModal} from '../modals';
import customPlaceholderAction from './custom-placeholder';
import {RECAPTCHA_PUBLIC_KEY} from "./recaptha";
import {reachGoal} from './../analytics';

const forms = document.querySelectorAll(`form:not(.form-native)`);
const successModal = document.querySelector(`.js-success-modal`);
const successContentBlock = document.querySelector(`.js-success-modal .success-modal__content`);
const errorModal = document.querySelector(`.js-error-modal`);
const errorContentBlock = document.querySelector(`.js-error-modal .success-modal__content`);
const isEnLang = (document.querySelectorAll('.site-id-s2').length !== 0);

const returnErrorTemplate = (text = '') => {
  return isEnLang ? `<h2>Error.</h2><p>${text}</p>` : `<h2>Возникла ошибка.</h2><p>${text}</p>`;
};

const returnSuccessTemplate = (type, text = '') => {
  if (isEnLang) {
    switch (type) {
      case `base`:
        return `<h2>Thank you for your message!</h2>`;
      case `summary`:
        return `<h2>Your resume has been sent successfully.</h2>`;
      case `appointment`:
        return `<h2>You have booked an appointment!</h2>
           <p>We’ll be in touch soon to discuss everything in more detail.</p>`;
      case `doctor_at_home`:
        return `<h2>Thank you for your request.</h2>
           <p>We’ll be in touch soon to discuss everything in more detail.</p>`;
      case `question`:
        return `<h2>Your question has been sent successfully.</h2>
           <p>We will look into it and be in touch soon. Thank you!</p>`;
      case `send-patient`:
        return `<h2>Your request was sent successfully.</h2>
         <p>Our employee will contact you as soon as possible.</p>`;
      case `submit-documents`:
        return `<h2>Documents sent successfully</h2>
         <p>The print version will be sent to the specified email address.</p>`;
      case `reviews`:
        return `<h2>Your review has been sent successfully. After moderation, the review will be published</h2>`;
      case `subscribe`:
        return `<h2>Thanks! You are subscribed to the newsletter</h2>`;
      case `author-blog`:
        return `<h2>Thanks! Your application has been submitted successfully</h2>`;
      case `partner`:
        return `<h2>Thanks! Your request was successfully sent</h2>`;
      case `programms`:
        return `<h2>Thank you! Your request has been sent. We will be in touch shortly.</h2>
            <p class="calculation-steps__slider-info">* All preliminary amounts calculated are estimated and do not represent a public offer.<br />
            **The clinic reserves the right to change the cost of the program depending on the individual nature of the patient participating in the program.
            </p>`;
      case 'callback':
        return '<h2>Your request was sent successfully.</h2><p>Our employee will contact you as soon as possible.</p>';
      default:
        return `<h2>Thank you for your message!</h2>`;
    }

  } else {

    switch (type) {
      case `base`:
        return `<h2>Спасибо за ваше обращение!</h2>`;
      case `summary`:
        return `<h2>Ваше резюме успешно отправлено.</h2>`;
      case `appointment`:
        return `<h2>Спасибо за заявку.</h2>
           <p>Наш сотрудник свяжется с Вами в ближайшее время и запишет на прием.</p>`;
      case `doctor_at_home`:
        return `<h2>Спасибо за заявку.</h2>
           <p>В ближайшее время с Вами свяжется наш сотрудник и уточнит детали.</p>`;
      case `question`:
        return `<h2>Ваш вопрос успешно отправлен</h2>
           <p>Мы рассмотрим его и свяжемся с вами. Спасибо!</p>`;
      case `send-patient`:
        return `<h2>Ваш запрос успешно отправлен.</h2>
         <p>Наш сотрудник свяжется с вами в ближайшее время.</p>`;
      case `submit-documents`:
        return `<h2>Документы успешно поданы</h2>
         <p>Версия для печати будет направлена на указанную почту.</p>`;
      case `reviews`:
        return `<h2>Ваш отзыв успешно отправлен. После модерации отзыв будет опубликован</h2>`;
      case `subscribe`:
        return `<h2>Спасибо!</h2><p>Подтвердите подписку, перейдя по&nbsp;ссылке в&nbsp;письме.</p>`;
      case `author-blog`:
        return `<h2>Спасибо! Ваша заявка успешно отправлена</h2>`;
      case `partner`:
        return `<h2>Спасибо! Ваше обращение успешно отправлено</h2>`;
      case `programms`:
        return `<h2>Заявка отправлена. Наш менеджер свяжется с вами в ближайшее время. Спасибо!</h2>
            <p class="calculation-steps__slider-info">* Расчет предварительный и не является публичной офертой.<br />
            **Клиника оставляет за собой право изменить стоимость программы в зависимости от индивидуальных особенностей пациента-участника программы.
            </p>`;
      case `service-exit`:
        return `<h2>Благодарим вас за заявку.</h2>
         <p>В ближайшее время с вами свяжется наш сотрудник и уточнит детали, для корректного расчёта.</p>`;
      case 'conference':
        return '<h2>Спасибо за заявку.</h2><p>Наш сотрудник свяжется с Вами в ближайшее время.</p>';
      case 'callback':
        return '<h2>Заявка успешно отправлена!</h2><p>В ближайшее время наш сотрудник свяжется с вами.</p>';
      default:
        return `<h2>Спасибо за ваше обращение!</h2>`;
    }

  }
};
const returnErrorInputText = (type) => {
  switch (type) {
    case 'empty':
      return isEnLang ? 'Field required to be entered' : 'Поле, обязательное для ввода';
    case 'phone':
      return isEnLang ? 'Invalid phone number entered' : 'Введен некорректный номер телефона';
    case 'email':
      return isEnLang ? 'Invalid email entered' : 'Введен некорректный email';
  }
};

const resetError = (elem) => {
  const inputs = elem.querySelectorAll('input');
  if (inputs.length) {
    inputs.forEach((el) => {
      const parent = el.closest('.custom-input');
      el.addEventListener('input', () => {
        if (parent && parent.classList.contains('custom-input--error')) {
          parent.classList.remove('custom-input--error');
        }
      });
      el.addEventListener('change', () => {
        if (parent && parent.classList.contains('custom-input--error')) {
          parent.classList.remove('custom-input--error');
        }
      });
    });
  }
};

const checkMailValidity = (el) => {
  const parent = el.closest('.custom-input'),
    errorBlock = parent.querySelector('.custom-input__error'),
    emailString = '^([A-z0-9_-]+.)*[A-z0-9_-]+@[A-z0-9_-]+(.[A-z0-9_-]+)*.[A-z]{2,6}$',
    regEmail = new RegExp(emailString, 'u');

  if (regEmail.test(el.value)) {
    return false;
  } else {
    errorBlock.innerText = returnErrorInputText('email');
    parent.classList.add('custom-input--error');
    return true;
  }
};

const resetForm = (elem) => {
  elem.reset();
  customPlaceholderAction();
};

const validateForm = (elem) => {
  const inputs = elem.querySelectorAll('input[required="required"]');
  let error = false;
  if (inputs.length) {
    inputs.forEach((el) => {
      const parent = el.closest('.custom-input'),
        errorBlock = parent.querySelector('.custom-input__error');
      if (el.classList.contains('agreement-check') && !el.checked) {
        parent.classList.add('custom-input--error');
        error = true;
      }
      if (!el.value) {
        errorBlock.innerText = returnErrorInputText('empty');
        parent.classList.add('custom-input--error');
        error = true;
      } else {
        if (el.getAttribute('inputmode') === 'tel' && el.value && el.value.length !== 16) {
          errorBlock.innerText = returnErrorInputText('phone');
          parent.classList.add('custom-input--error');
          error = true;
        }
        if (el.getAttribute('type') === 'email') {
          error = checkMailValidity(el);
        }
      }
    });
  }
  return error;
};

const initFormValidate = () => {
  let waitResponse = false;

  if (forms.length) {
    forms.forEach((form) => {
      const parent = form.closest(`.modal`);
      const type = form.dataset.type;
      const goal = form.dataset.goal;
      resetError(form);
      form.noValidate = true;
      form.addEventListener(`submit`, (evt) => {
        evt.preventDefault();
        if (!validateForm(form) && !waitResponse) {
          if (parent) {
            closeModal(parent, evt, false);
          }
          if (type) {
            const url = form.getAttribute('action');
            const formDataRequest = new FormData(form);
            window.closeMMenu();
            waitResponse = true;

            fetch(url, {
              method: 'POST',
              body: formDataRequest,
            })
              .then((response) => response.json())
              .then((result) => {
                if (result.status == 'ok') {
                  resetForm(form);
                  resetError(form);
                  successContentBlock.innerHTML = ``;
                  setTimeout(() => {
                    successContentBlock.insertAdjacentHTML(`afterbegin`, returnSuccessTemplate(type, result.message));
                    openModal(successModal, evt, false);
                    initializeModal(successModal, false);
                    reachGoal(goal);
                  }, 600);
                } else {
                  errorContentBlock.innerHTML = ``;
                  setTimeout(() => {
                    errorContentBlock.insertAdjacentHTML(`afterbegin`, returnErrorTemplate(result.message));
                    openModal(errorModal, evt, false);
                    initializeModal(errorModal, false);
                  }, 300);
                }

                if (form.querySelectorAll('.g-recaptcha').length > 0) {
                  let id = form.querySelector('.g-recaptcha').id;
                  let initParam = id.replace('g-recaptcha-response-', '');

                  grecaptcha.execute(RECAPTCHA_PUBLIC_KEY, {action: initParam}).then(function (token) {
                    document.getElementById(id).value = token;
                  });
                }

                waitResponse = false;
              });

          }
        } else {
          if (form.getAttribute('name') == 'SIMPLE_FORM_2' && form.querySelector('.form-errors')) {
            form.querySelector('.form-errors').style.display = 'block';
          }
        }
      });
    });
  }
};

export default initFormValidate;

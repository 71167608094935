import {Fancybox} from '../vendor/fancybox';

const imageParams = {
  idle: false,
  Images: {
    zoom: false,
    initialSize: 'fit'
  },
  Toolbar: {
    enabled: true,
    display: {
      left: [],
      middle: [],
      right: ['fullscreen', 'close'],
    },
  },
  Thumbs: false,
  Hash: false,
};

const initFancybox = () => {
  Fancybox.bind("[data-fancybox]", imageParams);
};

export {initFancybox};
